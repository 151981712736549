@import '@uikit/core/src/grid/media-queries';

//Legacy modal dialog styles
//Use with .modal-dialog and with .modal-service.modal-dialog to replicate the styles in legacy instead of .modal-service > .modal-dialog

.modal-dialog {
  max-width: 700px;
  margin: 10px auto;
  .mat-mdc-dialog-surface {
    &.mdc-dialog__surface {
      border-radius: 0px !important;
    }
  }
  .mdc-dialog__title {
    padding: 0px;
  }
  .mat-dialog-container {
    max-width: 700px;
  }
}
.mdc-dialog__title::before {
  display: inline !important;
}
.modal-service,
.modal-service-md {
  & + .modal-backdrop.in {
    opacity: 0.7;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  &.modal-dialog {
    box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.4);
    @media only screen and (max-width: map-get($breakpoints, 'xs')) {
      width: 90%;
    }
    @media only screen and (max-width: map-get($breakpoints, 'sm')) {
      width: 90%;
    }

    .mat-dialog-content {
      margin: 0px;
    }
  }
  .mat-dialog-container {
    border-radius: 0px !important;
    .dialog-header {
      background-color: themify(primary);
      padding: 25px;
      height: 136px;
      position: relative;
      display: flex;
      .mat-dialog-title.mat-mdc-dialog-title.mdc-dialog__title {
        font-size: 26px;
      }
      .ms-icon {
        font-size: 80px;
        width: 80px;
        height: 80px;
      }
      .ms-btn-close {
        background: transparent;
        border: none;
        color: themify(white);
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
      }
      .dialog-header__close {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
    .mat-dialog-content.mat-mdc-dialog-content.mdc-dialog__content {
      padding: 50px 60px 25px 60px !important;
      // padding: 40px !important;
      font-weight: 500;
      max-height: 60vh;
      overflow: auto;
      @media only screen and (max-width: map-get($breakpoints, 'xs')) {
        padding: 25px 25px 0 25px;
      }

      .ms-secondary-text {
        color: #6c7780;
        margin-top: 24px;
        &.m-sm {
          margin-top: 9px;
        }
      }
      .modal-link-summary {
        background-color: themify(light);
        padding: 15px;
        border-radius: 3px;
        a {
          margin-top: 10px;
          display: block;
          font-weight: bold;
        }
      }

      .link-redirect {
        margin-top: 1rem;
        padding: 0;
        cursor: pointer;
      }

      .something-text {
        font-size: 25px !important;
      }
      .aling-left {
        text-align: left;
        margin-left: 5%;
      }
    }
    h3.mat-dialog-title.mat-mdc-dialog-title.mdc-dialog__title {
      display: none;
      padding: 0px !important;
    }

    .mat-dialog-actions.mat-mdc-dialog-actions.mdc-dialog__actions {
      justify-content: center;
      padding: 0px 40px 40px 40px !important;
      box-sizing: inherit;
      @media only screen and (max-width: map-get($breakpoints, 'xs')) {
        padding: 25px;
      }

      button {
        flex: 0 !important;
        min-width: 195px;
        width: auto;
        margin: 0 12px;
        padding: 12px 10px;
        font-size: 17px;
        margin-bottom: 10px;
        @media only screen and (max-width: map-get($breakpoints, 'xs')) {
          min-width: 125px;
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}
//Modal adjustment for medium size
.modal-service-md {
  &.modal-dialog {
    max-width: 518px !important;
    &.legacy-edit-internal-transfer-modal {
      max-width: 558px !important;
    }
  }

  .modal-content {
    .modal-body {
      padding: 40px 60px 0 60px !important;
    }
  }
}
div.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing:first-of-type {
  @media only screen and (max-width: map-get($breakpoints, 'sm')) {
    opacity: 0 !important;
  }

  opacity: 1 !important;
}
div.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing:not(:first-of-type) {
  @media only screen and (max-width: map-get($breakpoints, 'sm')) {
    opacity: 0 !important;
  }
  opacity: 0.3 !important;
}

.internal-error-modal {
  max-width: 400px !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
  .mat-dialog-container {
    border-radius: 20px !important;
    .dialog-header {
      background-color: white !important;
      color: var(--udb-login-main-header-title-color);
      padding: 38px !important;
      border-radius: 0px !important;
      height: 0px !important;
    }
    .mat-dialog-content {
      border-top: 1px solid #ebebeb;
      margin-left: 24px;
      margin-right: 24px;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 24px;
      padding: 2px 0px 8px 0px !important;
      width: auto !important;
      span {
        line-height: 1.6em;
        font-size: 1em;
        color: #4a5560;
      }
      h1 {
        padding-bottom: 30px;
        line-height: 1em;
        font-size: 28px;
      }
    }
    .mat-dialog-actions {
      .new-button-okay {
        width: 100%;
        background: #2f5b88;
        color: #ffffff;
        border-radius: 8px !important;
        border-style: none;
      }
      padding: 0px 10px 16px 10px !important;
    }
  }
}

.modal-share-account-aas {
  right: 10px !important;
}

.modal-service,
.modal-service-md {
  .mat-dialog-container {
    .uk-btn.solid.secondary.lg.long-text-button.ng-star-inserted {
      min-width: 238px;
    }
    .btn-cancel.long-text-button.ng-star-inserted {
      min-width: 205px !important;
    }
    .mat-dialog-actions.mat-mdc-dialog-actions.mdc-dialog__actions {
      padding: 0 15px 40px !important;
    }
  }
}
