@mixin centered-body-text-modal($width, $height: inherit) {
  .uk2-modal {
    max-width: $width !important;
    min-width: $width !important;
    width: $width !important;
    min-height: $height !important;
  }

  &.cdk-overlay-pane {
    max-width: $width !important;
    min-width: $width !important;
    width: $width !important;
    min-height: $height !important;
  }
}
