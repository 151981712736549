/*
  Styles for embedded component from chatbot 3rd party
*/
.chatbot .abe-ew {
  &-header {
    background-image: none !important;
    background-color: var(--chatbot-secondary) !important;
    border-bottom: none !important;
  }

  &-conversation {
    background-image: none !important;
    background-color: var(--chatbot-primary) !important;
  }

  &-conversation-message-wrapper-sent {
    text-align: left !important;

    .abe-ew-conversation-message {
      background-color: var(--chatbot-secondary) !important;
      color: var(--chatbot-primary) !important;
    }
  }

  &-conversation-message-wrapper-received {
    .abe-ew-conversation-message {
      background-color: var(--chatbot-primary) !important;
      color: var(--chatbot-secondary) !important;
    }

    .quick-replies {
      padding-left: 9% !important;
      button {
        border: 1px solid #000;
        border-radius: 18px;
        padding: 3px 6px;
        margin: 4px;
        line-height: 18px;
        font-size: 11px;
        background-color: var(--chatbot-primary) !important;
        color: var(--chatbot-secondary) !important;
        border-style: solid !important;
        border-width: 2px !important;
        border-color: #1e3860 !important;
      }

      button:hover {
        background-color: var(--chatbot-secondary) !important;
        color: var(--chatbot-primary) !important;
      }
    }
  }

  &-conversation-message-timestamp {
    color: var(--chatbot-secondary) !important;
    opacity: 1 !important;
  }

  &-field {
    input {
      color: var(--chatbot-secondary) !important;
    }
  }

  &-conversation-context-collector {
    background-color: var(--chatbot-primary) !important;
    color: var(--chatbot-secondary) !important;
  }

  &-dialogue-panels {
    background-image: none !important;
    background-color: var(--chatbot-secondary) !important;
    color: var(--chatbot-primary) !important;
  }

  &-panel {
    background-image: none !important;
    box-shadow: none !important;
  }

  &-panel-faq {
    background-color: var(--chatbot-primary) !important;
    margin-top: -28px !important;
    padding-top: 30px !important;
  }

  &-start-conversation,
  &-start-conversation:hover {
    background: var(--chatbot-accent) !important;
    color: var(--chatbot-secondary) !important;
    box-shadow: none !important;
  }

  &-user-input-dark {
    input {
      box-shadow: none !important;
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
    }
  }

  &-title-info {
    text-transform: uppercase !important;

    .abe-ew-title-info-description {
      text-transform: none !important;
    }
  }

  &-message-feedback {
    .material-icons {
      color: var(--chatbot-secondary) !important;
    }
  }
}

.chatbot .abe-ew-interaction-img-wrapper,
.chatbot .abe-ew-interaction-roll-out-text {
  background-color: var(--chatbot-secondary) !important;
}

.chatbot.nationwide {
  .abe-ew-start-conversation,
  .abe-ew-start-conversation:hover {
    background: #0072cf !important;
    color: #f6f8f9 !important;
  }

  .abe-ew-conversation-message-wrapper-received .abe-ew-conversation-message {
    background-color: #f6f8f9 !important;
    color: #000000 !important;
  }
}

.abe-ew-widget,
.abe-ew-conversation-message,
.chatbot .abe-ew-start-conversation {
  font-family: 'Roboto', sans-serif !important;
}

.chatbot.nationwide {
  .abe-ew-conversation-message,
  .abe-ew-start-conversation {
    font-size: 13px !important;
  }
}
.chatbot.axos {
  .abe-ew-user-input textarea {
    font-size: 14px !important;
  }
}

.chatbot abe-embedded-web {
  z-index: 1039 !important;
}
