@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;
@import 'utilities/breakpoints';
@import 'utilities/material-mixins/mat-tab.mixin.scss';
@import 'utilities/material-mixins/mat-expansion-panel.mixin.scss';

.modal-dialog .mat-dialog-container {
  max-width: 90vw !important;
}

.modal-dialog:has(.investor-checking-enrollment) {
  max-width: fit-content;

  .mat-dialog-container {
    border-radius: 15px !important;
    width: 832px !important;
  }
}

/* --- Workaround to avoid importing entire prebuilt @angular/material theme --- */
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include cdk.overlay();

$axos: (
  primary: var(--primary),
  light: #348,
  dark: #254,
  contrast: (
    primary: rgba(black, 0.87),
    light: rgba(black, 0.87),
    dark: rgba(black, 0.87),
  ),
);
$dummy-theme: mat.define-palette(mat.$indigo-palette);
$dummy-app-accent: mat.define-palette($axos, primary, light, dark);
$dummy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $dummy-theme,
      accent: $dummy-app-accent,
    ),
  )
);

@include mat.legacy-core-theme($dummy-app-theme);
@include mat.legacy-dialog-theme($dummy-app-theme);
@include mat.legacy-button-theme($dummy-app-theme);
@include mat.legacy-form-field-theme($dummy-app-theme);
@include mat.legacy-select-theme($dummy-app-theme);
/* --- Workaround End --- */

/* @angular/material dialog styles */
.cdk-overlay-dark-backdrop {
  background-color: rgba(black, 0.7);
}

.modal-dialog {
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.3);

  .mat-dialog-container {
    max-width: 100vw;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }

  .mat-dialog-title {
    color: var(--primary);
    text-align: center;
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin: 3.125rem 5rem 1.5625rem 5rem;

    @include media-breakpoint-down(xs) {
      margin: 1rem 0.5rem;
    }
  }

  .mat-dialog-content {
    margin: 1rem 5rem 2rem 5rem;
    text-align: center;
    overflow: auto;
    padding: 0;

    @include media-breakpoint-only(sm) {
      margin: 1rem 3rem 2rem 3rem;
    }
    @include media-breakpoint-down(xs) {
      margin: 1rem 0.5rem;
    }
  }
  .mat-dialog-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    margin-bottom: 0;

    .uk-btn,
    .btn-ok,
    .btn-cancel,
    .button {
      width: 100%;

      &:first-child {
        margin-bottom: 1rem;
      }
    }

    @include media-breakpoint-up(sm) {
      flex-direction: row-reverse;
      padding: 0 2.5rem 2rem;

      .uk-btn,
      .btn-ok,
      .btn-cancel,
      .button {
        flex: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.pay-buttons {
  .mat-dialog-actions {
    flex-direction: column !important;
  }

  button {
    width: 60% !important;
    left: 0% !important;
    padding: 12px 10px !important;
  }

  .existing-payment {
    font-weight: normal;
    text-align: left;
  }

  .duplicate-title {
    margin: 0 50px 30px 50px;
  }

  .duplicate-continue {
    margin-top: 40px;
    font-weight: normal;
    text-align: center;
    font-size: larger;
  }

  .table--primary {
    margin-bottom: 40px;
    margin-right: 40px;
    margin-left: 40px;
  }
}

.dialog-header {
  display: none;
  background-color: var(--primary);
  background-size: cover;
  color: white;
  height: 8.5rem;
  padding: 1.5625rem;
  position: relative;
  text-align: center;

  &__close {
    color: white;
    background-color: transparent;
    border: none;
    position: absolute;
    padding: 0.625rem;
    right: 0.375rem;
    top: 0.375rem;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.full-screen-modal {
  max-width: unset !important;
  width: 100%;
  height: 100%;
  .mat-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}

/* --- Mat Tab styles ---*/
.material-tab {
  @include mat.legacy-tabs-theme($dummy-app-theme);
  @include mat-tab-content(var(--primary));
}

/* --- Mat Expansion styles ---*/
.material-expansion {
  @include mat.expansion-theme($dummy-app-theme);
  @include mat-expansion-panel-content(var(--primary));
}

.mat-datepicker-popup {
  font-family: var(--primary-font);
  display: flex;
  position: absolute;
  z-index: 1000;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;

  .mat-datepicker-content {
    pointer-events: auto;
    box-shadow: 0 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.2), 0 0.25rem 0.3125rem 0 rgba(0, 0, 0, 0.14),
      0 0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    display: block;
    border-radius: 0.25rem;
    transform-origin: left bottom;
  }
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-disabled
  > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-calendar-body-comparison-bridge-start::before,
[dir='rtl'] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-comparison-bridge-end::before,
[dir='rtl'] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-calendar-body-selected {
  background-color: var(--primary);
  color: #fff;
}
.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: var(--primary);
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--primary);
  color: white;
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir='rtl'] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--primary) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir='rtl'] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--primary) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--primary);
  color: #fff;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: var(--primary);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}
.mat-datepicker-content.mat-accent
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir='rtl'] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir='rtl'] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: #fff;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}
.mat-datepicker-content.mat-warn
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

.mat-calendar {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.mat-calendar-body {
  font-size: 13px;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-checkbox-layout,
.mat-radio-label {
  font-size: 1rem;
}

.mat-radio--align-start {
  .mat-radio-label {
    align-items: flex-start !important;
  }
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary);
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: #000;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--primary);
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primary);
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button .mat-ripple-element {
  background-color: #000;
}

.mat-expansion-panel {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
    background: #fff;
  }
}

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26);
}

.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  min-height: 48px;
}

.mat-expansion-panel-header.mat-expanded {
  min-height: 64px;
}

@include media-breakpoint-down(xs) {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

/* --- Mat Chip styles ---*/
@mixin _mat-chip-element-color($foreground, $background) {
  background-color: $background;
  color: $foreground;
  font-size: 12px;
  font-weight: 700;
  align-items: baseline;
}

.mat-chip.mat-standard-chip {
  @include _mat-chip-element-color(var(--primary), #f1f1f1);
}

/* --- Mat Toast styles --- */
.mat-icon.uk2-toast-icon.mat-icon-no-color {
  color: #fff;
}

.glitch-modal-error {

  .modal-service, .mat-dialog-container, .modal-service-md {
    border-radius: 20px !important;
  }

  .modal-service, .mat-dialog-container, #error-content.mat-dialog-content.mat-mdc-dialog-content.mdc-dialog__content, .modal-service-md {
    border-top: 1px solid #ebebeb;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 24px;
    padding: 2px 0px 8px 0px !important;
  }

  .modal-service.modal-dialog, .mat-dialog-content {
    font-weight: 500 !important;
    max-height: 60vh !important;
    overflow: auto !important;
    line-height: 1.6em !important;
  }

  .modal-dialog, .mat-dialog-container {
    min-width: 400px !important;
  }

  .modal-service, .mat-dialog-container, .dialog-header, .modal-service-md {
    background-image: none !important;
  }

  .dialog-header {
    padding: 38px !important;
    border-radius: 20px;
    background-color: white;
    height: 0px !important;
  }

  .modal-dialog {
    margin: 0px auto !important;
    width: auto !important;
  }

  #error-header.header-title {
    font-family: "Roboto", sans-serif !important;
    font-size: 26px !important;
    color: black !important;
    font-weight: 500 !important;
  }

  .modal-service, .modal-service-md {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    width: auto;
  }

  .modal-dialog, .mat-dialog-actions, .modal-service.modal-dialog {
    .btn-ok {
      border-radius: 8px !important;
      background: #2f5b88 !important;
      color: #ffffff !important;
      font-size: 17px !important;
      margin: 0 12px !important;
      padding: 12px 10px !important;
      min-width: 356px !important;
      max-width: 356px !important;
    }

    .new-button-okay {
      min-width: 356px !important;
      margin-bottom: 0px !important;
    }
  }

  .modal-service, .mat-dialog-container, #error-button.mat-dialog-actions, .modal-service-md {
    justify-content: center !important;
    padding: 0px 10px 16px 10px !important;
    margin-bottom: 0 !important;
  }

  .internal-error-modal .modal-dialog .modal-content .modal-footer {
    padding: 0px 10px 16px 10px !important;
  }

  .modal-service.modal-dialog, .modal-service-md.modal-dialog {
    width: auto !important;
  }
}

.cdk-global-overlay-wrapper:has(.cdk-overlay-pane-top-position) {
  z-index: 1010;
}
