.switch-tabs {
  display: block;
  text-align: center;
  position: relative;
  z-index: 2;
  a {
    display: inline-block;
    width: 8.75rem;
    text-align: center;
    height: 3.06rem;
    line-height: 3.06rem;
    margin: 0 0.31rem;
    color: #ffffff;
    font-size: 1rem;
    border: 0.06rem solid white;
    border-bottom: none;
    background-color: transparent;
    border-radius: 0.375rem 0.375rem 0rem 0rem;
    &.active {
      border-bottom: none;
      background-color: #faa74a;
      font-weight: 600;
      color: #1e3860;
      border: none;
    }
  }
}
