@import 'node_modules/@axos/uikit-v2-lib/src/styles/uk2-common/uk2-breakpoints.scss';
@import '../common/modal-mixins.scss';

.modal-medium-size {
  @include centered-body-text-modal(400px);
  .mat-mdc-dialog-content {
    max-height: 55vh;
  }
  @include md {
    @include centered-body-text-modal(640px);
    .mat-mdc-dialog-content {
      max-height: 65vh;
    }
  }
}

.udb-modal-small {
  @include centered-body-text-modal(327px);
  .uk2-modal {
    .uk2-modal-section-content {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }
  }

  @include md {
    @include centered-body-text-modal(440px);
  }
}

.uk2-modal .mat-mdc-dialog-actions.uk2-modal-side-grid button {
  min-width: inherit !important;
}

.loan-payoff-outsystems-custom-modal {
  .uk2-modal {
    width: 83vw !important;
    height: 85vh !important;
  }

  .mat-mdc-dialog-content {
    max-height: 75vh !important;
    height: 100% !important;
  }

  .uk2-modal-section-content {
    padding: 0 !important;
    width: 100% !important;
  }

  .uk2-modal-content-center {
    height: 100% !important;
    overflow: hidden !important;
  }
}

.model-selection-outsystems-custom-modal {
  .mat-mdc-dialog-container {
    min-width: 99vw !important;
  }

  .uk2-modal {
    min-width: 99vw !important;
    width: 99w !important;
    height: 100vh !important;
  }

  .mat-mdc-dialog-content {
    max-height: 89vh !important;
    height: 100% !important;
  }

  .uk2-modal-section-content {
    padding: 0 !important;
    width: 100% !important;
  }

  .uk2-modal-content-center {
    height: 100% !important;
    overflow: hidden !important;
  }
}

.distribution-modal-dialog-container {
  .mat-mdc-dialog-container {
    max-width: 496px !important;
  }

  .uk2-modal {
    .mat-mdc-dialog-title {
      font-size: 20px !important;
      line-height: 28px !important;
      padding: 16px 24px !important;
    }
  }

  .uk2-modal-section-content {
    padding: 24px 24px !important;
  }

  p .model-content {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 24px !important;
  }
}
