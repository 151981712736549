@mixin mat-tab-content($color-primary) {
  display: flex;
  justify-content: center;

  .mat-ink-bar {
    display: none;
  }

  .mat-label-content {
    font-size: 1rem;
    line-height: 1.375rem;
    z-index: 1;
  }

  .mat-tab {
    &-list {
      display: flex;
      justify-content: center;
    }

    &-label-container {
      margin-bottom: -1px;
    }

    &-header {
      overflow: inherit;
    }

    &-label {
      border: 1px solid #e9eaec;
      border-radius: 0;
      opacity: 1;
      background-color: #f4f5f5;
      font-weight: 700;
      margin: 0 0.375rem;
      min-width: 10.25rem;
      padding: 1rem 2.25rem;
      color: $color-primary;

      &-active {
        box-shadow: inset 0 3px $color-primary;
        margin: 0 5px;
        border-bottom: none !important;
        color: $color-primary;
        background: #fff;
        border-bottom: 1px solid #fff !important;
      }
    }
  }
}
