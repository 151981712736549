// LIVE CHAT MAIN CONTAINER; shown window above Feedback Tab but below pop-up messages.
.modalContainer.embeddedServiceSidebar {
  z-index: 1025 !important;
}

@media only screen and (min-width: 480px) {
  // LIVE CHAT CONTAINER
  .dockableContainer {
    margin-right: 30px !important;
  }

  // LIVE CHAT BUTTON
  .embeddedServiceHelpButton .helpButton,
	// LIVE CHAT BUTTON MINIMIZED */
	.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer {
    margin-right: 30px !important;
  }
}

// LIVE CHAT BUTTON
.embeddedServiceHelpButton .helpButton .uiButton,
// LIVE CHAT BUTTON MINIMIZED */
.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer {
  cursor: pointer !important;
  min-width: 10em !important;
  margin-right: 30px !important;

  &:hover {
    font-weight: bold !important;
  }
  &:hover::before,
  &:focus::before {
    opacity: 0 !important;
  }

  // LIVE CHAT BUTTON LABEL
  .helpButtonLabel {
    font-size: 14px !important;
  }

  // LIVE CHAT BUTTON - CHAT ICON
  .embeddedServiceIcon {
    display: none !important;
    padding: 0px;
    margin: 0px !important;
    width: 0 !important;
    opacity: 0 !important;
  }
}

// LIVE CHAT BUTTON MINIMIZED
.embeddedServiceSidebarMinimizedDefaultUI {
  &.minimizedContainer,
    // LIVE CHAT BUTTON MINIMIZED; Return focus state to default.
    &.minimizedContainer:focus {
    width: auto !important;
    border-width: 0 !important;
    text-decoration: none !important;
  }
}

// PRE-CHAT FORM
.embeddedServiceSidebarFormField .uiInput {
  // INPUT FIELDS; Remove asterisks for required fields on form validation.
  .required {
    display: none !important;
  }
  // INQUIRY TYPE; Remove icon to avoid duplicity.
  select {
    background-image: none !important;
  }
}

// CHAT HEADER
.embeddedServiceSidebarHeader {
  .headerItem:focus::after,
  .headerButton:focus::after {
    border: none !important;
  }
}

// BUTTON COMPONENT
.embeddedServiceSidebarButton.uiButton {
  &:hover {
    font-weight: bold !important;
    text-decoration: none !important;

    // BUTTON LABEL
    .label {
      font-weight: bold !important;
    }
  }

  // BUTTON COMPONENT; Return focus state to default.
  &:focus {
    text-decoration: none !important;
    box-shadow: none !important;
  }

  // BUTTON LABEL
  .label {
    font-weight: normal !important;
    font-size: 14px !important;
  }
}
