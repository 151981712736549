a.link,
button.link {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--link);

  &:not(:disabled) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--muted);
  }
}

.text-muted {
  color: var(--muted);
}
