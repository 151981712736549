@import '@uikit/core/src/grid/media-queries';

//Legacy modal dialog styles
//Use with .modal-dialog and with .modal-service.modal-dialog to replicate the styles in legacy instead of .modal-service > .modal-dialog

.modal-dialog {
  max-width: 700px;
  margin: 10px auto;
  .mat-mdc-dialog-surface {
    &.mdc-dialog__surface {
      border-radius: 0px !important;
    }
  }
  .mat-dialog-container {
    color: inherit !important;
    padding: 0px !important;
    box-shadow: none !important;
  }
  .dialog-header__close {
    padding: 0 !important;
  }
}
.modal-service,
.modal-service-md {
  & + .modal-backdrop.in {
    opacity: 0.7;
  }
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  &.modal-dialog {
    box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.4);
    @media only screen and (max-width: map-get($breakpoints, 'xs')) {
      width: 90%;
    }
    @media only screen and (max-width: map-get($breakpoints, 'sm')) {
      width: 90%;
    }

    .mat-dialog-content {
      .modal-link {
        padding-bottom: 15px;
      }
      margin: 0px;
    }
    .modal-link {
      padding-bottom: 15px;
    }
  }
  .modal-content {
    .modal-header {
      background-color: themify(primary);
      padding: 25px;
      height: 136px;
      position: relative;
      display: flex;
      .modal-title {
        font-size: 26px;
      }
      .ms-icon {
        font-size: 80px;
        width: 80px;
        height: 80px;
      }
      .ms-btn-close {
        background: transparent;
        border: none;
        color: themify(white);
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
      }
      .ms-icon-close {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
    .modal-body {
      padding: 40px;
      font-weight: 500;
      max-height: 60vh;
      overflow: auto;
      @media only screen and (max-width: map-get($breakpoints, 'xs')) {
        padding: 25px 25px 0 25px;
      }
      .ms-secondary-text {
        color: #6c7780;
        margin-top: 24px;
        &.m-sm {
          margin-top: 9px;
        }
      }
      .modal-link-summary {
        background-color: themify(light);
        padding: 15px;
        border-radius: 3px;
        a {
          margin-top: 10px;
          display: block;
          font-weight: bold;
        }
      }

      .link-redirect {
        margin-top: 1rem;
        padding: 0;
        cursor: pointer;
      }

      .something-text {
        font-size: 25px;
      }
    }
    .modal-footer {
      padding: 40px 40px 40px 40px;
      @media only screen and (max-width: map-get($breakpoints, 'xs')) {
        padding: 25px;
      }
      .ms-footer-btns {
        @media only screen and (max-width: map-get($breakpoints, 'xs')) {
          flex-direction: column-reverse;
        }
        button {
          min-width: 176px;
          margin: 0 12px;
          padding: 12px 10px;
          font-size: 17px;
          @media only screen and (max-width: map-get($breakpoints, 'xs')) {
            min-width: 125px;
            width: 100%;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

//Modal adjustment for medium size
.modal-service-md {
  &.modal-dialog {
    max-width: 518px !important;
  }
  .modal-content {
    .modal-body {
      padding: 40px 60px 0 60px;
    }
  }
}
@media (max-width: 767px) {
  .custom-modal.modal-content {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0 !important;
  }
}

// .internal-error-modal {
//   .modal-dialog {
//     max-width: 400px;
//     box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
//     .modal-content {
//       border-radius: 20px;
//       .modal-header {
//         background-color: white;
//         color: var(--udb-login-main-header-title-color);
//         padding: 38px;
//         border-radius: 20px;
//         height: 0px;
//       }
//       .modal-body {
//         border-top: 1px solid #ebebeb;
//         margin-left: 24px;
//         margin-right: 24px;
//         border-bottom: 1px solid #ebebeb;
//         margin-bottom: 24px;
//         padding: 2px 0px 8px 0px !important;
//         span {
//           line-height: 1.6em;
//           font-size: 1em;
//           color: #4a5560;
//         }
//         h1 {
//           padding-bottom: 30px;
//           line-height: 1em;
//           font-size: 28px;
//         }
//       }
//       .modal-footer {
//         .new-button-okay {
//           width: 100%;
//           background: #2f5b88;
//           color: #ffffff;
//           border-radius: 8px !important;
//           border-style: none;
//         }
//         padding: 0px 10px 16px 10px !important;
//       }
//     }
//   }
// }

// .additional-account-needed {
//   .modal-dialog {
//     .modal-content {
//       .modal-body {
//         span {
//           line-height: 1.5em;
//           font-size: 1em;
//         }
//         h1 {
//           padding-bottom: 30px;
//           line-height: 1em;
//           font-size: 28px;
//         }
//       }
//       .modal-footer {
//         button {
//           width: 100%;
//         }
//       }
//     }
//   }
// }
