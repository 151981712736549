@import 'utilities/breakpoints';

.button {
  --btn-background: white;
  $self: &;
  width: 100%;
  height: var(--btn-height);
  position: relative;
  box-sizing: border-box;
  font-family: var(--btn-font);
  font-size: 1rem;
  transition: all 0.3s ease-in;
  text-align: center;
  font-weight: var(--btn-font-weight);
  border-radius: var(--btn-border-radius);
  color: var(--btn-primary-txt-color);
  padding: 0.75rem 1.375rem;
  max-width: 18rem;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &--solid {
    border: none;
    background: var(--btn-background);

    &:after {
      left: 1.5rem;
      transform: rotate(225deg) translateY(50%);
    }

    &:not(.arrow--left):after {
      left: initial;
      transform: rotate(45deg) translateY(-50%);
      right: 1.5rem;
    }

    &.arrow--none:after {
      display: none;
    }

    &#{$self}--primary {
      --btn-background: var(--primary);
      --btn-arrow-color: var(--btn-accent-bkg-color);
      color: var(--btn-primary-txt-color);

      &:hover,
      &:focus {
        &:not(:disabled) {
          --btn-background: var(--btn-primary-solid-hover-color);
        }
      }
    }

    &#{$self}--accent {
      --btn-background: var(--btn-accent-bkg-color);
      --btn-arrow-color: var(--primary);
      color: var(--btn-accent-txt-color);

      &:hover,
      &:focus {
        &:not(:disabled) {
          --btn-background: var(--btn-accent-solid-hover-color);
        }
      }
    }
  }

  &--outline {
    --border-color: initial;
    --btn-primary-txt-color: var(--primary);
    --btn-background: transparent;
    border: 1px solid var(--border-color);
    background: var(--btn-background);

    &:after {
      transform: rotate(45deg) translateY(-50%);
      right: 1.5rem;
    }

    &:not(.arrow--right):after {
      right: initial;
      left: 1.5rem;
      transform: rotate(225deg) translateY(50%);
    }

    &.arrow--none:after {
      display: none;
    }

    &#{$self}--primary {
      --border-color: var(--btn-primary-bkg-color);
      --btn-arrow-color: var(--accent);

      &:hover,
      &:focus {
        &:not(:disabled) {
          --btn-primary-txt-color: var(--btn-primary-outline-txt-hover-color);
          --btn-background: var(--btn-primary-outline-hover-color);
          border-width: var(--btn-accent-hover-border-width);
        }
      }
    }

    &#{$self}--accent {
      --border-color: var(--btn-accent-bkg-color);
      --btn-arrow-color: var(--primary);

      &:hover,
      &:focus {
        &:not(:disabled) {
          --btn-primary-txt-color: var(--btn-accent-outline-txt-hover-color);
          --btn-background: var(--btn-accent-outline-hover-color);
          border-width: var(--btn-accent-hover-border-width);
        }
      }
    }
  }

  &--solid,
  &--outline {
    &:hover,
    &:focus {
      &:not(:disabled) {
        text-shadow: 1px 0 0 var(--btn-primary-txt-color);
      }
    }

    &:after {
      display: inline-block;
      position: absolute;
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      top: 50%;
      transform-origin: center;
      border-right: var(--btn-arrow-width) solid;
      border-top: var(--btn-arrow-width) solid;
      border-color: var(--btn-arrow-color);
    }
  }

  &--lg {
    max-width: 10.875rem;
  }

  &--sm {
    max-width: 8.5rem;
  }
}

.btn-ok {
  @extend .button;
  @extend .button--accent;
  @extend .button--solid;
}

.btn-cancel {
  @extend .button;
  @extend .button--primary;
  @extend .button--outline;
}

.tab-btn {
  font-family: var(--tab-btn-font-family);
  display: inline-block;
  border: 1px solid #e9eaec;
  border-bottom: 0;
  border-radius: var(--tab-btn-radius);
  background: var(--tab-btn-bkg);
  font-weight: 400;
  margin: 0 0.25rem;
  padding: 1rem 1.75rem;
  min-width: 9.375rem;
  color: var(--tab-btn-color);

  &--active {
    color: var(--tab-btn-color-active);
    background: var(--tab-btn-bkg-active);
    font-weight: bold;
    border-color: var(--tab-btn-bkg-active);
  }

  @include media-breakpoint-down(sm) {
    &--lg {
      min-width: 14.25rem;
    }

    border-bottom-width: 1px;
  }
}
