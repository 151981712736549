@mixin tileStyles {
  .uikit-tile {
    > .uikit-tile-container > .uikit-tile-front {
      > .uikit-tile-header {
        height: 73px !important;
        padding-bottom: 10px !important;
      }

      > .uikit-tile-fill {
        height: 260px !important;
      }
    }
  }
}

.account-trends {
  @include tileStyles;
}

.account-distribution {
  @include tileStyles;
}
