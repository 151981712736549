@mixin mat-expansion-panel-content($color-primary) {
  display: flex;
  flex-direction: column;

  .mat-expanded,
  .mat-expansion-panel-header {
    background-color: #f4f5f5;
    border-radius: 0%;
    font-weight: 500;
  }

  .mat-expansion {
    &-panel {
      margin: 0.375rem 0;
      box-shadow: 0 0 #000 !important;
    }

    &-panel-header {
      padding: 0 1rem;
      height: 3rem;
    }

    &-panel-header.mat-expanded {
      height: 3rem;
    }

    &-panel-content {
      background-color: #fff;
    }

    &-panel-body {
      padding: 0;
    }
  }
}
