/* You can add global styles to this file, and also import other style files */
/* Check that this file is using LF instead of CRLF format */
@import './scss/chatbot.scss';
@import './scss/utilities/utilities';
@import './scss/live-chat.scss';
@import './scss/glance.component.scss';
@import './scss/material.scss';
@import './scss/buttons.scss';
@import './scss/tabs.scss';
@import './scss/uikit-overrides.scss';
@import './scss/modals/legacy-modals.scss';
@import './scss/modals/mat-dialog-modals.scss';
@import './scss/modals/base-modal.scss';
@import './scss/fonts.scss';
@import './scss/tiles/base-tile.scss';

option[value=''] {
  display: none;
}

.uk-switch input,
input[type='checkbox'],
input[type='radio'] {
  // TODO: Remove the following important once UI Kit is completely gone.
  // https://stackoverflow.com/a/27568825
  display: initial !important;
  width: 1px;
  height: 1px;
}

.invalid-field,
.invalid-field .uk-input {
  border-color: var(--danger);
}

.invalid-field .uk-label,
.invalid-field .reveal-label__text,
.invalid-field .reveal-label__btn {
  color: var(--danger);
}
.invalid-field .reveal-label .svg-icon {
  fill: var(--danger) !important;
}

.text-underline {
  text-decoration: underline;
}

// This section is intended to apply styles to mat-menu components.
// They need to be globally accessible because if they are added to the parent component stylesheet
// Angular's default scoping will make them not applicable.
.mat-menu-panel {
  min-height: initial !important;
}

.menu {
  &__account-select {
    // Unfortunately, we cannot escape important here as Angular Material adds inline styles.
    max-width: 18.75rem !important;
    margin-top: 0.5rem;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.4);

    .mat-menu-content:not(:empty) {
      max-height: 24.125rem;
      padding: 0;
    }
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-34.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-form-field
  .mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  font-size: 16px;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(16px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}

.card-limits {
  .mat-expansion-panel-header {
    padding: 0;
    font-weight: 350;

    .mat-content {
      max-width: max-content;
    }

    .mat-expansion-indicator {
      margin-left: 5px;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 0 1rem 0;
  }
}

.view-container {
  max-width: 90rem;
  margin: 0 auto;
}

.account-banner__amount {
  font-size: 1.75rem;
  font-weight: lighter;
}

.form__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  [class*='btn-'] {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .btn-ok {
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row-reverse;

    .btn-ok {
      margin-left: 1.5rem;
    }
  }
}

.btn-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  color: #0070d9;
  background: none;
  border: none;
}

.focus-checkbox:focus + label {
  outline: 3px auto black !important;
}

@import 'node_modules/@axos/uikit-v2-lib/src/styles/uk2.scss';

@include uk2-menu-button-item-content(16, 24, 16, 24);
