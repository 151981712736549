// Bootstrap variables overrides.
$grid-gutter-width: 2rem;

@import 'angular-ui-tree/dist/angular-ui-tree.min.css';
@import 'angular-ui-carousel/dist/ui-carousel.min.css';
@import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@import 'bootstrap-daterangepicker/daterangepicker.scss';
@import 'angucomplete-alt/angucomplete-alt.css';
@import 'quill/dist/quill.core.css';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/utilities/_text';

#__tealiumGDPRecModal {
  position: fixed;
  bottom: 0;
  z-index: 1;
}
